<template>
  <div class="df-flex-xl df-flex-col">
    <df-button
      color-icon="#39AF49"
      max-width="208"
      variant="text-green"
      :icon="shouldShowComplementaryData ? 'chevron-up' : 'chevron-down'"
      @click="toggleComplementaryData"
    >
      {{ $t('SowingsPlanning.execute_season.complementary_data') }}
    </df-button>
    <div v-show="shouldShowComplementaryData" class="df-flex-xl df-flex-col">
      <div class="df-flex-l">
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <df-select
            v-model="selectedSeedClass"
            :items="seedClasses"
            :label="$t('SowingsPlanning.execute_season.seed_class')"
            :loading="loadingSeedClasses"
            :placeholder="$t('SowingsPlanning.execute_season.select')"
          />
        </div>
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <df-input
            v-model="germinativePower"
            type="number"
            suffix="%"
            :label="$t('SowingsPlanning.execute_season.germinative_power')"
            :placeholder="$t('SowingsPlanning.execute_season.type')"
          />
        </div>
      </div>
      <div class="df-flex-l">
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <df-input
            v-model="seedVigor"
            type="number"
            suffix="%"
            :label="$t('SowingsPlanning.execute_season.seed_vigor')"
            :placeholder="$t('SowingsPlanning.execute_season.type')"
          />
        </div>
        <div class="df-col-6 df-col-12-t df-col-12-m df-col-12-t df-col-12-m">
          <df-select
            v-model="seederSystem"
            :items="seederSystems"
            :label="$t('SowingsPlanning.execute_season.seed_system')"
            :loading="loadingSeederSystems"
            :placeholder="$t('SowingsPlanning.execute_season.select')"
          />
        </div>
      </div>
      <div class="df-flex-l">
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <df-input
            v-model="lineSpacing"
            type="number"
            suffix="cm"
            :label="$t('SowingsPlanning.execute_season.line_spacing')"
            :placeholder="$t('SowingsPlanning.execute_season.type')"
          />
        </div>
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <df-input
            v-model="populationLines"
            type="number"
            :label="$t('SowingsPlanning.execute_season.population_line')"
            :placeholder="$t('SowingsPlanning.execute_season.type')"
            :suffix="
              $t('SowingsPlanning.execute_season.population_line_suffix')
            "
          />
        </div>
      </div>
      <div class="df-flex-l">
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <df-input
            v-model="sownQuantity"
            type="number"
            suffix="Kg/ha"
            :label="$t('SowingsPlanning.execute_season.sown_quantity')"
            :placeholder="$t('SowingsPlanning.execute_season.type')"
          />
        </div>
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <df-input
            v-model="operatingSpeed"
            type="number"
            suffix="Km/h"
            :label="$t('SowingsPlanning.execute_season.operating_speed')"
            :placeholder="$t('SowingsPlanning.execute_season.type')"
          />
        </div>
      </div>
      <div class="df-flex-l df-flex-col-m">
        <div class="df-col-6 df-col-12-m">
          <df-radio-button
            v-model="origin"
            :label="$t('SowingsPlanning.execute_season.origin')"
            :options="originOptions"
          />
        </div>
        <div class="df-col-6 df-col-12-m">
          <df-radio-button
            v-model="seedTreatment"
            :label="$t('SowingsPlanning.execute_season.seed_treatment')"
            :options="yesOrNoOptions"
          />
        </div>
      </div>
      <div class="df-flex-l">
        <div class="df-col-6 df-col-12-m">
          <df-radio-button
            v-model="inoculant"
            :label="$t('SowingsPlanning.execute_season.inoculant')"
            :options="yesOrNoOptions"
          />
        </div>
        <div class="df-col-6 df-col-12-m">
          <df-radio-button
            v-model="harvested"
            :label="$t('SowingsPlanning.execute_season.harvested')"
            :options="yesOrNoOptions"
          />
        </div>
      </div>
      <div class="df-flex-l df-flex-col">
        <div class="df-flex-xm df-flex-col">
          <df-input
            v-model="harvestedQuantity"
            suffix="sc/ha"
            type="number"
            :disabled="!shouldEnableHarvestedQuantity"
            :label="$t('SowingsPlanning.execute_season.harvested_quantity')"
            :placeholder="$t('SowingsPlanning.execute_season.type')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfInput from '@/lib/components/Input/DfInput.vue'
import DfRadioButton from '@/lib/components/Button/DfRadioButton.vue'
import DfSelect from '@/lib/components/Select/DfSelect.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'SowingComplementaryData',

  components: {
    DfButton,
    DfInput,
    DfRadioButton,
    DfSelect,
  },

  props: {
    taskExtraInfo: {
      default: () => ({}),
      type: Object,
    },
  },

  data() {
    return {
      harvested: null,
      harvestedQuantity: null,
      inoculant: null,
      germinativePower: null,
      lineSpacing: null,
      loadingSeedClasses: false,
      loadingSeederSystems: false,
      operatingSpeed: null,
      origin: null,
      populationLines: null,
      seedClasses: [],
      seedTreatment: null,
      seedVigor: null,
      seederSystem: {},
      seederSystems: [],
      selectedSeedClass: {},
      showComplementaryData: false,
      sownQuantity: null,
    }
  },

  computed: {
    originOptions() {
      return [
        {
          label: this.$t('SowingsPlanning.execute_season.bought'),
          value: 'bought',
        },
        {
          label: this.$t('SowingsPlanning.execute_season.owned'),
          value: 'owned',
        },
      ]
    },
    shouldEnableHarvestedQuantity() {
      return this.harvested === 'yes'
    },
    shouldShowComplementaryData() {
      return this.showComplementaryData
    },
    yesOrNoOptions() {
      return [
        {
          label: this.$t('SowingsPlanning.execute_season.no'),
          value: 'no',
        },
        {
          label: this.$t('SowingsPlanning.execute_season.yes'),
          value: 'yes',
        },
      ]
    },
  },

  methods: {
    async fillComplementaryData() {
      this.germinativePower = this.taskExtraInfo.germinative_power
      this.seedVigor = this.taskExtraInfo.seed_vigor
      this.lineSpacing = this.taskExtraInfo.line_spacing
      this.populationLines = this.taskExtraInfo.population_lines
      this.sownQuantity = this.taskExtraInfo.sown_quantity
      this.operatingSpeed = this.taskExtraInfo.operating_speed
      this.origin = this.taskExtraInfo.origin
      this.seedTreatment = this.taskExtraInfo.seed_treatment
      this.inoculant = this.taskExtraInfo.inoculant
      this.harvested = this.taskExtraInfo.harvested
      this.harvestedQuantity = this.taskExtraInfo.harvest_quantity
      this.loadingSeedClasses = true
      this.loadingSeederSystems = true
      try {
        const [allSeedClasses, allSeederSystems] = await Promise.allSettled([
          sowingsPlanningService.getSeedClasses(),
          sowingsPlanningService.getSeederSystems(),
        ])
        if (allSeedClasses.status === 'fulfilled') {
          this.seedClasses = allSeedClasses.value.data.map((seedClass) => {
            return {
              name: this.$t(seedClass.messageKey),
              value: String(seedClass.id),
            }
          })
          if (this.taskExtraInfo.seed_class_id) {
            this.selectedSeedClass = this.seedClasses.find(
              (seedClass) => seedClass.value == this.taskExtraInfo.seed_class_id
            )
            this.selectedSeedClass.message_key = this.selectedSeedClass.name
          }
        }
        if (allSeederSystems.status === 'fulfilled') {
          this.seederSystems = allSeederSystems.value.data.map(
            (seederSystem) => {
              return {
                name: this.$t(seederSystem.messageKey),
                value: String(seederSystem.id),
              }
            }
          )
          if (this.taskExtraInfo.seeder_system_id) {
            this.seederSystem = this.seederSystems.find(
              (seederSystem) =>
                seederSystem.value == this.taskExtraInfo.seeder_system_id
            )
            this.seederSystem.message_key = this.seederSystem.name
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingSeedClasses = false
        this.loadingSeederSystems = false
      }
    },
    toggleComplementaryData() {
      this.showComplementaryData = !this.showComplementaryData
      if (this.showComplementaryData) {
        logEvent(
          events.sowingsPlanningModule.SowingsPlanning.complementary_data
        )
        this.fillComplementaryData()
      }
    },
  },
}
</script>

<style></style>
